 // app

(function($) {

    'use strict';

    window.AppWBZGV = {

        // Define the selectors
        window: $(window),
        body: $('body'),
        html: $('html'),
        page: $('.page'),
        hasDropDownSelector: '.has-dropdown',
        mainNavigationSelector: '.main-navigation',
        mobileMenuSelector: '.mobile-menu',
        subNavigationSelector: '.sub-navigation',
        toggleMobileSearchSelector: '.toggle-mobile-search',
        stappenblokTabsSelector: '.stappen-blok-holder',
        opleidingenblokTabsSelector: '.werkgebied-description',
        sliderSelector: '.swiper-container',
        fixedHeaderClass: 'fixed-header',
        subNavigationLoaded: false,

        /**
         * Initialise the object
         */
        init: function() {

	        	console.log('app-wbzgv.js initialized...');

            var self = this;

            // Initialize mobile menu
            $(this.mobileMenuSelector).mmenu();

            this.bind();
            this.getIEVersion();

            console.log("Modernizr['object-fit']", Modernizr['object-fit']);

            if (!Modernizr['object-fit']) {
              $('.positionering-blok .image-holder').each(function () {
                var $imagecontainer = $(this).find('figure'),
                    imgUrl = $imagecontainer.find('img').prop('src');
                if (imgUrl) {
                  $imagecontainer.css('backgroundImage', 'url(' + imgUrl + ')').addClass('compat-object-fit');
                }
              });
            }

            var $tabsBaseUrl = $('base');
            if ($tabsBaseUrl.length > 0) {
            	var locationHr = document.location.href;
            	var indexOf = locationHr.indexOf('#');
            	if (indexOf >= 0) {
            		locationHr = locationHr.substr(0, indexOf);
            	}
                $(this.stappenblokTabsSelector + ' a.tab, ' + this.opleidingenblokTabsSelector + '  a.tab').each(function () {
                    var $tab = $(this);
                    var attHr = $tab.attr('href');
                    if (attHr.substr(0, 1) == '#') {
                        $tab.attr('href', locationHr + attHr);
                    }
                });
            };

            // Initialize accordion stappenblok
            $(this.stappenblokTabsSelector).tabs({
                active: 0,
                collapsible: true
            });

            // Initialize accordion opleidingenblok
            $(this.opleidingenblokTabsSelector).tabs({
                active: false,
								collapsible: true
            });

            // Initialize slider
            $(this.sliderSelector).each(function() {
                new self.Slider($(this));
            });
        },
       /**
         * Detect IE browser version
         */
		    getIEVersion: function() {
			    console.log('getIEVersion');
				  var userAgent = window.navigator.userAgent;
				  var Idx = userAgent.indexOf("MSIE");
				  // If IE, return version number
				  if (Idx > 0) {
				    return parseInt(userAgent.substring(Idx+ 5, userAgent.indexOf(".", Idx)));
				  } else if (!!navigator.userAgent.match(/Trident\/7\./)) { // If IE 11 then look for Updated user agent string.
					  $('body').addClass('ie11');
				    return 11;
					} else {
				    return 0; // It is not IE
				  }
				},

        /**
         * Set up event handlers
         */
        bind: function() {
            var self = this;

            //Scroll browserview to selected jQuery.tab
            function scrollBrowserview(e){
                var target = $(e.target),
                    pageOffset = $(document).scrollTop();

                setTimeout(function () {
                    var offsetTarget = target.offset().top;
                    $('html, body').animate({
                        scrollTop: (offsetTarget - 80)
                    }, 300);
                }, 350);
            };

            //Fire scrollBrowserview to selected jQuery.tab
            $(this.stappenblokTabsSelector).on('click', '.tab', function (e) { scrollBrowserview(e) });
            $(this.opleidingenblokTabsSelector).on('click', '.tab', function (e) { scrollBrowserview(e) });
        },
    };

}(jQuery));

(function ($) {

  'use strict';

})(jQuery);

$(document).ready(function() {
    AppWBZGV.init();
});
